const layout = {
  polar: {
    radialaxis: {
      tickfont: {
        size: 8,
      },
    },
    angularaxis: {
      tickfont: {
        size: 8,
      },
      rotation: 90,
      direction: "counterclockwise",
    },
  },
};

/**
 * Setea los 4 parametros necesarios para armar plot de densidad de datos
 * usando el array de las posiciones en eje x (xPosition) y array posiciones en eje y (yPosition)
 *
 * @param {array} radius Array de numeros de distancia desde el centro
 * @param {array} anglesTheta Array de numeros de angulos en radianes
 * @return {array} Array listo para ser ingresaso al componente plotly como data
 */
function data2ChartPlot(radius, anglesTheta) {
  var polarScatter = {
    type: "scatterpolar",
    mode: "lines+markers",
    r: radius,
    theta: anglesTheta,
    line: {
      color: "#2DCB7F",
    },
    marker: {
      color: "#008294",
      symbol: "square",
      size: 8,
    },
  };
  return [polarScatter];
}
/**
 * Funcion para transformar de coordenadas cartesianas a polares
 * los array de coordenadas deben tener el mismo largo
 * @param {array} x posicion eje x
 * @param {array} y posicion eje y
 * @return {object} object de forma [radius,theta] donde ambos son array
 */
function cartesian2polar(x, y) {
  let radius = [];
  let theta = [];
  if (x.length == y.length) {
    x.forEach((el, index) => {
      radius[index] = Math.sqrt(el ^ (2 + y[index]));
      theta[index] = Math.atan(y[index] / el);
    });
  } else {
    console.error("arguments of different lengths");
  }

  return {radius, theta};
}

export { layout, data2ChartPlot, cartesian2polar };
