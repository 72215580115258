<template>
<div>
  <h3>Distribution axis movement chart</h3>
  <Plotly
    :data="data"
    :layout="layout"
    :display-mode-bar="true"
    :watchShallow="watchShallow"
    :autoResize="autoResize"
  ></Plotly>
</div>
</template>

<script>
import { Plotly } from "vue-plotly";
import newPoints from "./dataMaker";
import { layout, data2DistributionPlot } from "./dataLayoutDistribution";

export default {
  name: "distributionChart",
  components: {
    Plotly,
  },
  data() {
    return {
      layout: {},
      watchShallow: false,
      autoResize: true,
      data: [],
      timeoutBetweenRequests: 0,
    };
  },
  methods: {},
  mounted() {
    const points = newPoints();
    this.data = data2DistributionPlot(points.x, points.y);
    this.layout = layout;
  },
  watch: {},
};
</script>
