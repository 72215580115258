const layout = {
  showlegend: true,
  margin: { t: 50 },
  hovermode: "closest",
  bargap: 0.05, 
  bargroupgap: 0.05, 
  xaxis: {
    domain: [0, 0.85],
    showgrid: false,
    zeroline: false,
  },
  yaxis: {
    domain: [0, 0.85],
    showgrid: false,
    zeroline: false,
  },
  xaxis2: {
    domain: [0.85, 1],
    showgrid: false,
    zeroline: false,
  },
  yaxis2: {
    domain: [0.85, 1],
    showgrid: false,
    zeroline: false,
  },
};

/**
 * Setea los 4 parametros necesarios para armar plot de densidad de datos
 * usando el array de las posiciones en eje x (xPosition) y array posiciones en eje y (yPosition)
 * 
 * @param {array} xPosition Array de numeros de las posiciones en eje x
 * @param {array} yPosition Array de numeros de las posiciones en eje y
 * @param {string} [names=['points','density','x density','y density']]  Array opcional de nombres de graficos
 * @param {string} [colors=["rgb(102,0,0)",'YlGnBu',"rgb(82,150,165)","rgb(82,150,165)"]] Array opcional de colores (posicion 1 es gradiente)
 * @return {array} Array listo para ser ingresaso al componente plotly como data
 */

const colorScale=[['0.0','#D2FAE0'],['0.1666','#00BA88'],['0.3333','#00A88F'],['0.5','#009593'],['0.6666','#008294'],['1.0','#006F8F']]

function data2DistributionPlot(xPosition,yPosition,names=['points','density','x density','y density'],colors=["rgb(102,0,0)",colorScale,colorScale[2][1],colorScale[3][1]]){
    var trace1 = {
        x: xPosition,
        y: yPosition,
        mode: "markers",
        name: names[0],
        marker: {
          color: colors[0],
          size: 2,
          opacity: 0.25
        },
        type: "scatter"
      };
      var trace2 = {
        x: xPosition,
        y: yPosition,
        name: names[1],
        ncontours: 20,
        colorscale: colors[1],
        reversescale: false,
        showscale: false,
        type: "histogram2dcontour"
      };
      var trace3 = {
        x: xPosition,
        name: names[2],
        marker: { color: colors[2] },
        yaxis: "y2",
        type: "histogram"
      };
      var trace4 = {
        y: yPosition,
        name: names[3],
        marker: { color: colors[3] },
        xaxis: "x2",
        type: "histogram"
      };
    return [trace1, trace2, trace3, trace4]
}

export {layout,data2DistributionPlot}