<!-- 
Componente padre
  AnnotationPanel (dialog que muestra una carta de la anotacion elegida y la linea de tiempo)
    AnnotationLine (La linea de tiempo)
      AnnotationCard (Cada anotacion en la linea de tiempo)
  PlotlyGraph
-->

<template>
  <v-card>
    <!-- Panel modal -->
    <annotation-panel
      :annotationsList="annotations"
      :showPanel="showPanel"
      :annotationStandOutIndex="annotationStandOutIndex"
      @panel-open="panelOpened()"
    />
    <!-- Plot -->
    <plotly-graph
      v-if="this.layout.annotations"
      :plotData="[plotData]"
      :layout="layout"
      @plotly-click="pointClicked($event)"
    />
    <v-skeleton-loader
      v-else
      class="mx-auto"
      max-width="300"
      type="card"
    ></v-skeleton-loader>
  </v-card>
</template>

<script>
/**
 * Componente de graficos con anotaciones y panel de anotaciones
 *
 *
 */
import PlotlyGraph from "../PlotlyGraph";
import AnnotationPanel from "./AnnotationPanel.vue";
//! transformar en props
import { dummydata } from "./dummydata";

export default {
  components: { PlotlyGraph, AnnotationPanel },
  name: "ThePlotLineAnnotations",
  created() {
    //Armando datos desde dummy data ¿convertir en un fetch?
    this.plotData = dummydata;
    this.$store
      .dispatch("annotations/getAnnotations", {
        //la idea seria indicar sobre que mediciones y entre que tiempos se quiere las anotaciones
        content: "nothing",
      })
      .then((resAnnotation) => {
        this.annotations = resAnnotation;
        this.layout.annotations = resAnnotation.map((el) => {
          return {
            x: el.date,
            y: el.value,
            text: el.letter,
            arrowcolor: el.focus ? "#04E200" : "#797979",
          };
        });
      });
  },
  data() {
    return {
      /**
       * data to be plot (must enter as array)
       */
      plotData: {},
      /**
       * annotations
       */
      annotations: [],
      /**
       * layout of plot
       */
      layout: {},
      /**
       * to show dialog/modal
       */
      showPanel: false,
      /**
       * index of the annotation to on 'annotations'
       * that must be stand out
       */
      annotationStandOutIndex: 0,
    };
  },
  computed: {
    /**
     * Array of dates of all annotations
     * to be used to know if annotation was clicked
     */
    datesInAnnotation: function () {
      return this.annotations.map((el) => {
        return el.date;
      });
    },
  },
  props: {},
  methods: {
    /**
     * if clicked point has a annotation, then show dialog/modal
     */
    pointClicked: function (point) {
      const indexMatch = this.datesInAnnotation.findIndex((el) => {
        return el == point.x;
      });

      if (indexMatch >= 0) {
        this.annotationStandOutIndex = indexMatch;
        this.showPanel = true;
      }
    },
    panelOpened: function () {
      this.showPanel = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
