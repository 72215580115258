<!-- Componente wrapper de plottly -->
<template>
  <div :id="refName" style="width: 100%"></div>
</template>

<script>
import Plotly from "plotly.js-dist";

/**
 * Simple Plotly Wrapper
 * @displayName
 */
export default {
  name: "PlotyGraph",
  data() {
    return {
      /**
       * Contain instance of Plotly when component mounted
       */
      myPlot: null,
      refName: "plotlyPlace" + Math.floor(Math.random() * (1000 - 0 + 1) + 0),
      annotations: [],
    };
  },
  props: {
    /**
     * Data to be plot (multiArray)
     */
    plotData: {
      type: Array,
      required: true,
    },
    /**
     * Graph layout
     * Object as described on plotly.com/javascript/layout-template/
     */
    layout: {
      type: Object,
      required: true,
    },
    plotlyConfig: {
      type: Object,
      default: () => ({
        responsive: true,
      }),
    },
  },
  mounted() {
    setTimeout(() => {
      Plotly.newPlot(
        this.refName,
        this.plotData,
        this.layout,
        this.plotlyConfig
      )
        .then((result) => {
          result.on("plotly_click", (data) => {
            this.$emit("plotly-click", data);
          });
          this.$nextTick(() => {
            this.myPlot = result;
            Plotly.relayout(this.refName, this.layout);
          });
        })
        .then(() => {})
        .catch((err) => {
          console.error("Error during newPlot:", err);
        });
    }, 1000);
  },
  methods: {},
  computed: {},
  watch: {
    plotData: {
      deep: true,
      handler: function (newValue, oldValue) {
        Plotly.react(this.refName, newValue, this.layout);
      },
    },
    layout: function (newValue) {
      Plotly.react(this.refName, this.plotData, newValue);
    },
  },
};
</script>