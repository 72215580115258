<template>
  <v-card
    class="px-6 py-0"
    :elevation="standOut ? '8' : '0'"
    rounded
    outlined
    max-width="400"
  >
    <!-- Titulo -->
    <v-card-title>
      <v-text-field
        v-model="editAnnotation.title"
        :counter="20"
        label="Title"
        required
        :prefix="`(${editAnnotation.letter})`"
        :readonly="!editMode"
      ></v-text-field>
    </v-card-title>
    <!-- Fecha y tiempo -->
    <v-card-subtitle>
      <v-row>
        <v-col>
          <!-- Fecha -->
          <form-datepicker
            @date-pick="setNewDate($event)"
            :pDate="editDate"
            :editMode="editMode"
          />
        </v-col>
        <v-col>
          <!-- Tiempo -->
          <form-timepicker
            @time-pick="setNewTime($event)"
            :ptime="editTime"
            :editMode="editMode"
          />
        </v-col>
      </v-row>
    </v-card-subtitle>
    <!-- Descripcion -->
    <v-card-text>
      <v-textarea
        name="input-description"
        label="Description"
        :value="editAnnotation.description"
        hint="Make a great description for the annotation!"
        :readonly="!editMode"
        rows="2"
    /></v-card-text>
    <v-spacer></v-spacer>
    <!-- Botones -->
    <v-card-actions>
      <v-btn-toggle class="px-3 py-0">
        <!-- Save -->
        <v-btn icon small :depressed="!canSaveBeUse" :disabled="!canSaveBeUse">
          <v-icon color="black">{{ saveIcon }}</v-icon>
        </v-btn>
        <!-- Not save -->
        <v-btn
          icon
          small
          :depressed="!canSaveBeUse"
          :disabled="!canSaveBeUse"
          @click="saveAllChanges()"
        >
          <v-icon> {{ cancelIcon }} </v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <!-- Boton borrar anotacion -->
      <v-btn
        icon
        small
        :depressed="!editMode"
        :disabled="!editMode"
        @click="discardChanges()"
      >
        <v-icon color="error">{{ deleteIcon }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
//* En este componente se debe iniciar la modificacion de las anotaciones hacia el server
//* Probablemente mediante el uso de Vuex
import { mdiDelete, mdiContentSave, mdiCancel } from "@mdi/js";

import { clone as ldClone } from "lodash";

import FormDatepicker from "./FormDatepicker.vue";
import FormTimepicker from "./FormTimepicker.vue";

export default {
  components: { FormDatepicker, FormTimepicker },
  name: "AnnotationCard",
  created() {
    this.editAnnotation = ldClone(this.annotation); //clone shallow
  },
  data() {
    return {
      /**
       * Iconos en uso
       */
      saveIcon: mdiContentSave,
      deleteIcon: mdiDelete,
      cancelIcon: mdiCancel,
      /**
       * Elemento interno para manejar cambios como paso intermedio y no tocar el prop
       */
      editAnnotation: null,
      /**
       * Para saber si la data a sido guardada luego de iniciar una edicion
       */
      isDataSave: true,
    };
  },
  props: {
    // datos de la anotacion en tarjeta
    annotation: {
      type: Object,
      required: true,
    },
    // es esta la anotacion elegida por el usuario?
    standOut: {
      type: Boolean,
      default: false,
    },
    // está el panel en modo edicion?
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => {
      this.isDataSave = true;
    }, 500);
  },
  methods: {
    setNewDate: function (newdate) {
      let separateTime = this.editAnnotation.date.split(" ", 2);
      separateTime[0] = newdate;
      this.editAnnotation.date = separateTime.join(" ");
      //en el wath de editAnnotation se da cuenta de que existió un cambio
    },
    setNewTime: function (newTime) {
      let separateTime = this.editAnnotation.date.split(" ", 2);
      separateTime[1] = newTime;
      this.editAnnotation.date = separateTime.join(" ");
      //en el wath de editAnnotation se da cuenta de que existió un cambio
    },
    // para guardar los cambios al presionar en save
    saveAllChanges: function () {
      this.isDataSave = true;
      this.$store.dispatch("annotations/updateAnnotation");
    },
    // para descartar los cambios al presionar cancel.
    discardChanges: function () {
      this.isDataSave = false;
      this.editAnnotation = ldClone(this.annotation);
    },
  },
  watch: {
    // si cambia la anotacion, actualizar los datos a mostrar
    annotation: {
      handler: function (newval) {
        this.editAnnotation = ldClone(newval); //clone shallow
      },
      deep: true,
    },
    // si ocurre una edicion
    editAnnotation: {
      handler: function () {
        if (this.editMode) {
          this.isDataSave = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    editDate: function () {
      let separateTime = this.editAnnotation.date.split(" ", 2);
      return separateTime[0];
    },
    editTime: function () {
      let separateTime = this.editAnnotation.date.split(" ", 2);
      return separateTime[1];
    },
    //Para saber si puedo mostrar los botones
    canSaveBeUse: function () {
      return this.editMode == true && this.isDataSave == false;
    },
  },
};
</script>
