<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :disabled="!editMode"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        label="Time"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      full-width
      @click:minute="() => $refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import { clone as ldClone } from "lodash";

export default {
  name: "FormTimepicker",
  created() {
    this.time = ldClone(this.ptime);
  },
  data() {
    return {
      time: null,
      menu: false,
    };
  },
  props: {
    ptime: {
      type: String,
    },
    editMode: {
      type: Boolean,
    },
  },
  methods: {},
  watch: {
    time: function (val) {
      this.$emit("time-pick", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
