
/**
 * Entrega un conjunto de puntos en coordenadas cartesianas
 *
 * @param {*} numberOfPoints cantidad de puntos requeridos
 * @return {object} objeto de x e y, cada uno un array
 */
function newPoints(numberOfPoints) {
  function normal() {
    var x = 0,
      y = 0,
      rds,
      c;
    do {
      x = Math.random() * 2 - 1;
      y = Math.random() * 2 - 1;
      rds = x * x + y * y;
    } while (rds == 0 || rds > 1);
    c = Math.sqrt((-2 * Math.log(rds)) / rds); // Box-Muller transform
    return x * c; // throw away extra sample y * c
  }

  var a = -1,
    b = 1.2;

  var step = (b - a) / (numberOfPoints - 1);
  var t = new Array(numberOfPoints),
    x = new Array(numberOfPoints),
    y = new Array(numberOfPoints);

  for (var i = 0; i < numberOfPoints; i++) {
    t[i] = a + step * i;
    x[i] = Math.pow(t[i], 3) + 0.3 * normal();
    y[i] = Math.pow(t[i], 6) + 0.3 * normal();
  }
  return { x, y };
}

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
/**
 *
 *
 * @param {*} lenght
 * @param {*} min
 * @param {*} max
 * @return {*} 
 */
function getRndArray(lenght, min, max) {
  let ii = 0;
  let rndArray = [];
  while (ii < lenght) {
    rndArray[ii] = getRndInteger(min, max);
    ii++;
  }
  return rndArray;
}

export { newPoints, getRndInteger, getRndArray };
