<template>
  <div>
    <v-snackbar
      id="snackbar_sns"
      v-model="showSnackbar"
      color="teal darken-1"
      absolute
      right
      top
    >
      {{ snackbarText }}
    </v-snackbar>
    <md-content>
      <h3>
        Simple Notification System: Try sending a SMS to your phone number
      </h3>
      <p>Events can be link to triggers for emergency notification</p>
      <md-field id="phone">
        <label>Phone Number</label>
        <span class="md-prefix">+569</span>
        <md-input v-model="phonenumber" maxlength="8"></md-input>
      </md-field>
      <md-button class="md-raised" @click="sendPost" :class="[`md-${getTheme}`]"
        >SEND</md-button
      >
    </md-content>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "snsForm",
  data() {
    return {
      endpoint:
        "https://ooskcrj9fd.execute-api.us-east-1.amazonaws.com/default/SMSfromTGC",
      endpoint2: "https://entr733gjmvyh.x.pipedream.net",
      phonenumber: "",
      message:
        "Enviado desde el sistema de alerta - Mensaje Demo - Saludos desde The Group Cloud!",
      showSnackbar: false,
      snackbarTimeout: 4000,
      snackbarText: "Sending SMS",
    };
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  methods: {
    sendPost: async function () {
      //check if is a phone number?
      const payload = {
        PhoneNumber: "+569" + this.phonenumber,
        Message: this.message,
      };
      fetch(this.endpoint, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
        body: JSON.stringify(payload),
      });
      this.showSnackbar = true;
    },
  },
};
</script>
