<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">Distribution Chart</h4>
        </md-card-header>
        <md-card-content>
          <distribution-chart />
        </md-card-content>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">Radial Chart</h4>
        </md-card-header>
        <md-card-content>
          <radial-chart />
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100">
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">SNS</h4>
        </md-card-header>
        <md-card-content>
          <sns-form />
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100">
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">System notification</h4>
        </md-card-header>
        <md-card-content>
          <h3>System notification: Create an internal notification</h3>
          <p>Events automatically generate these types of notifications</p>
          <notification-demo />
        </md-card-content>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">Map</h4>
        </md-card-header>
        <md-card-content>
          <big-map />
        </md-card-content>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">Graph with Annotations</h4>
        </md-card-header>
        <md-card-content>
          <the-plot-line-annotations />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DistributionChart from "../components/Monitoring/DistributionChart/distributionChart";
import RadialChart from "../components/Monitoring/RadialChart/radialChart";
import SnsForm from "../components/Monitoring/snsForm";
import BigMap from "../components/Maps/BigMap";
import NotificationDemo from "../components/Notifications/NotificationDemo";
import ThePlotLineAnnotations from "../components/Monitoring/PlotAnnotations/ThePlotLineAnnotations.vue";

export default {
  components: {
    DistributionChart,
    RadialChart,
    SnsForm,
    BigMap,
    NotificationDemo,
    ThePlotLineAnnotations,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>