<template>
  <md-button
    class="md-raised"
    @click="insertNotification"
    :class="[`md-${getTheme}`]"
    >Create a notification</md-button
  >
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    insertNotification() {
      this.$store.dispatch("notification/notificationDemo", {
        message:
          "System demo notification created at " +
          new Date().toString().split(" (")[0],
      });
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>
