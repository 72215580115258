<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="!editMode"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        label="Date"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
      ></v-text-field>
    </template>
    <v-date-picker v-if="menu" v-model="date" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="() => (menu = false)"> Cancel </v-btn>
      <v-btn text color="primary" @click="() => $refs.menu.save(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { clone as ldClone } from "lodash";

export default {
  name: "FormDatepicker",
  created() {
    this.date = ldClone(this.pDate);
  },
  data() {
    return {
      // date: new Date().toISOString().substr(0, 10),
      menu: false,
    };
  },
  props: {
    pDate: {
      type: String,
    },
    editMode: {
      type: Boolean,
    },
  },
  methods: {},
  watch: {
    date: function (val) {
      this.$emit("date-pick", val);
    },
  },
};
</script>
