<template>
<div>
  <h3>Radial position axis chart</h3>
  <Plotly
    :data="data"
    :layout="layout"
    :display-mode-bar="true"
    :watchShallow="watchShallow"
    :autoResize="autoResize"
  ></Plotly>
</div>
</template>

<script>
import { Plotly } from "vue-plotly";
import { newPoints, getRndArray } from "./dataMaker";
import { layout, data2ChartPlot } from "./dataLayoutChart.js";

export default {
  name: "radialChart",
  components: {
    Plotly,
  },
  data() {
    return {
      layout: {},
      watchShallow: false,
      autoResize: true,
      data: [],
      timeoutBetweenRequests: 0,
    };
  },
  methods: {
    generatePolarPoints: function (amount, min, max) {
      const radius = newPoints(amount);
      const angles = getRndArray(amount, min, max);
      return { radius, angles };
    },
  },
  mounted() {
    //const points = newPoints(300);
    //const polarPoints = cartesian2polar(points.x, points.y);
    // Genero 200 puntos, de radios distribuidos normalmente y de angulos con distribucion uniforme entre 0 y 360
    const coords = this.generatePolarPoints(100, 0, 360);
    this.data = data2ChartPlot(coords.radius, coords.angles);
    this.layout = layout;
  },
  watch: {},
};
</script>
