<!-- Componente que forma la linea de tiempo de las anotaciones -->
<template>
  <v-card>
    <!-- Datos de anotacion elegida -->
    <v-card-title>
      Note: {{ annotationList[annotationStandOutIndex].title }}
    </v-card-title>
    <v-card-subtitle>
      <p>{{ annotationList[annotationStandOutIndex].description }}</p>
    </v-card-subtitle>
    <!-- Linea de tiempo de las anotaciones -->
    <v-card-text>
      <v-timeline dense>
        <v-timeline-item
          v-for="(annotation, index) in annotationList"
          :key="'TimelineItem' + index"
          :color="index == annotationStandOutIndex ? 'green' : 'blue-grey lighten-1'"
        >
          <!-- Icono de la anotacion -->
          <template v-slot:icon>
            <v-icon color="white">{{ iconsByType[annotation.type] }}</v-icon>
          </template>
          <!-- Contenido de la anotacion -->
          <annotation-card
            :annotation="annotation"
            :standOut="index == annotationStandOutIndex"
            :editMode="editMode"
          />
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
/**
 * Component of timeline for annotations
 * Usage on dialog
 *
 */
import {
  mdiTools,
  mdiFireAlert,
  mdiCheckCircle,
  mdiPencilOutline,
  mdiDelete,
} from "@mdi/js";
import AnnotationCard from "./AnnotationCard.vue";

export default {
  components: { AnnotationCard },
  name: "AnnotationLine",
  created() {},
  data() {
    return {
      /**
       * Iconos a utilizar
       */
      iconsByType: [mdiTools, mdiFireAlert, mdiCheckCircle],
      pencilIcon: mdiPencilOutline,
      deleteIcon: mdiDelete,
    };
  },
  props: {
    /**
     * List of annotations to be displayed
     */
    annotationList: {
      type: Array,
      required: true,
    },
    /**
     * Index of the annotation to be stand out
     */
    annotationStandOutIndex: {
      type: Number,
      required: false,
    },
    /**
     * Para bajar la info de que se puede editar
     */
    editMode: {
      type: Boolean,
    },
  },
  mounted() {
    //* Ordenar datos para linea de tiempo (annotationList)
    //*
  },
  methods: {},
};
</script>
