<!-- Componente modal/dialog que despliega un contenido (en este caso especial para annotation-line) -->

<template>
  <v-dialog
    class="d-inline"
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <!-- Button to test -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>
    </template>
    <!-- Toolbar to open and close -->
    <v-toolbar dark :color="editMode ? 'success' : 'primary'">
      <v-btn icon dark @click="dialog = false">
        <v-icon>{{ closeIcon }}</v-icon>
      </v-btn>
      <v-toolbar-title>Timeline of annotations</v-toolbar-title>
      <v-icon v-show="editMode" class="px-2">{{ editIcon }}</v-icon>
      <v-spacer></v-spacer>
      <!-- button to the right -->
      <v-toolbar-items>
        <v-container class="py-4" fluid>
          <v-switch
            v-model="editMode"
            :label="`Edit Mode`"
            color="orange"
          ></v-switch>
        </v-container>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-btn dark text @click="dialog = false"> Save </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <!-- Dialog content -->

    <annotation-line
      :annotationList="annotationsList"
      :annotationStandOutIndex="annotationStandOutIndex"
      :editMode="editMode"
    />

    <!-- Dialog Content end -->
  </v-dialog>
</template>

<script>
import { mdiPlaylistEdit, mdiClose } from "@mdi/js";

import AnnotationLine from "./AnnotationLine.vue";
export default {
  components: { AnnotationLine },
  name: "AnnotationPanel",
  created() {},
  data() {
    return {
      // iconos a utilizar
      editIcon: mdiPlaylistEdit,
      closeIcon: mdiClose,
      // to show or not the dialog
      dialog: false,
      // el usuario está usando el modo edicion?
      editMode: false,
    };
  },
  props: {
    // array of annotations
    annotationsList: {
      type: Array,
    },
    // from parent to show the dialog - to be watch
    showPanel: {
      type: Boolean,
    },
    // index de la anotacion elegida por el usuario
    annotationStandOutIndex: {
      type: Number,
      required: false,
    },
  },
  methods: {},
  watch: {
    // if the parent want to show, make dialog show and emit that the value must change
    showPanel: function (val) {
      if (val) {
        this.dialog = true;
        this.$emit("panel-open");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
